import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { SRLWrapper } from "simple-react-lightbox"

import { Wifi2 } from "@styled-icons/boxicons-regular/Wifi2"
import { PlayFill } from "@styled-icons/bootstrap/PlayFill"
import { Calendar } from "@styled-icons/boxicons-regular/Calendar"
import { AccessTime } from "@styled-icons/material/AccessTime"

import * as S from "../components/Pages/general"

const IndexPage = () => {
  const {
    highlightImage,
    module01Image,
    module02Image,
    module03Image,
    module04Image,
    module05Image,
    module06Image,
    bonus01Image,
    bonus03Image,
    bonus04Image,
    metodoGarantiaImage,
    depoimento1,
    depoimento2,
    depoimento3,
    depoimento4,
    depoimento5,
    depoimento6,
    depoimento7,
    depoimento8,
    depoimento9,
    depoimento10,
    depoimento11,
    depoimento12,
    safeImage,
  } = useStaticQuery(
    graphql`
      query {
        highlightImage: file(relativePath: { eq: "animes.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        module01Image: file(
          relativePath: { eq: "metodo-fan-art-modulo-1.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        module02Image: file(
          relativePath: { eq: "metodo-fan-art-modulo-2.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        module03Image: file(
          relativePath: { eq: "metodo-fan-art-modulo-3.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        module04Image: file(
          relativePath: { eq: "metodo-fan-art-modulo-4.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        module05Image: file(
          relativePath: { eq: "metodo-fan-art-modulo-5.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        module06Image: file(
          relativePath: { eq: "metodo-fan-art-modulo-6.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bonus01Image: file(relativePath: { eq: "metodo-fan-art-bonus-1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bonus03Image: file(relativePath: { eq: "metodo-fan-art-bonus-3.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bonus04Image: file(relativePath: { eq: "metodo-fan-art-bonus-4.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        metodoGarantiaImage: file(
          relativePath: { eq: "metodo-fan-art-garantia-7-dias.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento1: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento1.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento2: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento2.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento3: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento3.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento4: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento4.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento5: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento5.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento6: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento6.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento7: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento7.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento8: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento8.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento9: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento9.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento10: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento10.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento11: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento11.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        depoimento12: file(
          relativePath: { eq: "depoimentos/metodo-fan-art-depoimento12.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        safeImage: file(relativePath: { eq: "metodo-fan-art-seguranca.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const options = {
    buttons: {
      backgroundColor: "rgba(30,30,36,0.8)",
      iconColor: "rgba(255, 255, 255, 0.8)",
      iconPadding: "5px",
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
      size: "40px",
    },
  }

  return (
    <Layout>
      <SEO title="Método Fan Art 3.0" />
      <S.GeneralWrapper>
        <S.GeneralTitle>
          Método Fan Art - O melhor curso online de desenho
        </S.GeneralTitle>
        <S.GeneralHighlight>
          Se seu sonho é desenhar seus personagens favoritos de anime ou criar
          seus próprios personagens, tenha certeza que você chegou ao lugar
          certo. Aqui te apresentarei o melhor método para aprender a desenhar,
          de forma prática e totalmente online
        </S.GeneralHighlight>
        <Img
          fluid={highlightImage.childImageSharp.fluid}
          alt="Imagem com vários personagens de animes"
        />
        <S.GeneralText>
          Acredito que todo mundo já teve vontade de desenhar um dia e muitos
          ainda tem, porém, com o passar do tempo e às vezes responsabilidades
          novas chegando, acaba nos afastando desse sonho. Mas não se preocupe,
          hoje você irá conhecer o melhor curso de desenho da atualidade.
        </S.GeneralText>
        <S.GeneralText>
          Hoje quero trazer para vocês um método totalmente novo e inovador para
          que você possa aprender a desenhar de casa. O método Fan Art 3.0 é um
          curso online voltado para quem quer aprender a desenhar seus
          personagens de anime ou até mesmo criar seus próprios personagens.
        </S.GeneralText>
        <S.GeneralText>
          Feito sob medida para quem aspira ser um grande desenhista e também
          para quem já começou e quer aprimorar ainda mais seus traços.
        </S.GeneralText>
        <S.GeneralSeparator />
        <S.GeneralCallToAction
          target="_blank"
          href="http://bit.ly/aprendendo-desenhar-fan-art"
        >
          Aproveite essa oferta agora em até 10x!!!
        </S.GeneralCallToAction>
        <S.GeneralSeparator />
        <S.GeneralSubTitle>O que você vai aprender no curso</S.GeneralSubTitle>
        <S.GeneralText>
          Estudando e aplicando o{" "}
          <S.GeneralStrongText>método Fan Art 3.0</S.GeneralStrongText> você
          será capaz de:
        </S.GeneralText>
        <S.GeneralList>
          <S.GeneralListItem>
            - Com apenas um lápis e uma folha em branco, desenhar seu personagem
            favorito.
          </S.GeneralListItem>
          <S.GeneralListItem>
            - Entender toda a estrutura de base para criar personagens
            perfeitos.
          </S.GeneralListItem>
          <S.GeneralListItem>
            - Conquistar a habilidade de desenhar em poucas semanas. Sem
            precisar de um processo longo como nas escolas tradicionais.
          </S.GeneralListItem>
        </S.GeneralList>
        <S.GeneralStrongText>E ainda será preparado para:</S.GeneralStrongText>
        <S.GeneralList>
          <S.GeneralListItem>
            - Desenhar sem ter vergonha de seus amigos ou familiares, inclusive
            terá orgulho da nova habilidade artística.
          </S.GeneralListItem>
          <S.GeneralListItem>
            - Chega de usar folha vegetal ou passar o desenho por cima para
            poder ter seus próprios desenhos.
          </S.GeneralListItem>
          <S.GeneralListItem>
            - Conseguirá recriar cenas e personagens apenas olhando para eles.
          </S.GeneralListItem>
        </S.GeneralList>
        <S.GeneralStrongText>Mesmo que:</S.GeneralStrongText>
        <S.GeneralList>
          <S.GeneralListItem>
            - Você não tenha o "dom de desenhar".
          </S.GeneralListItem>
          <S.GeneralListItem>
            - Acredita que não tenha idade ( temos alunos de 8 a 40 anos).
          </S.GeneralListItem>
          <S.GeneralListItem>
            - Já tenteou outras tecnicas antes e acabou se frustando, seja com
            cursos ou até mesmo escolas tradicionais.
          </S.GeneralListItem>
          <S.GeneralListItem>
            - Acha que necessita de anos e anos para aprender.
          </S.GeneralListItem>
        </S.GeneralList>
        <S.GeneralSubTitle>Quais são os módulos?</S.GeneralSubTitle>
        <S.GeneralText>
          O método Fan Art 3.0 é um curso de desenho totalmente online, onde
          você irá aprender desde o início até técnicas avançadas de desenho.
        </S.GeneralText>
        <S.GeneralText>
          Afim de maximizar o aprendizado para que você consiga realizar seus
          desejos a professora Mayara Rodrigues separou o curso em alguns
          módulos para organizar melhor seu aprendizado, assim potencializando
          sua forma de aprender, sem que se torne algo chato e custoso.
        </S.GeneralText>
        <S.GeneralText>Abaixo te mostro cada módulo no detalhe:</S.GeneralText>
        <S.GeneralSection>
          <S.GeneralBox>
            <S.Generalh3>Módulo 1</S.Generalh3>
            <S.GeneralTextTitle>Análise de Imagens</S.GeneralTextTitle>
            <Img
              fluid={module01Image.childImageSharp.fluid}
              alt="Imagem com o módulo 1 do curso, mostrando o painel do curso."
            />
            <S.GeneralTextCapitular>
              Fazendo uma boa análise de imagens de forma mais profunda,
              percebendo detalhes que muitas vezes passam despercebidos e
              entendendo a diferença entre um desenho profissional e um amador.
            </S.GeneralTextCapitular>
          </S.GeneralBox>
          <S.GeneralBox>
            <S.Generalh3>Módulo 2</S.Generalh3>
            <S.GeneralTextTitle>Análise de Rostos</S.GeneralTextTitle>
            <Img
              fluid={module02Image.childImageSharp.fluid}
              alt="Imagem com o módulo 2 do curso, mostrando o painel do curso."
            />
            <S.GeneralTextCapitular>
              Aqui nesse módulo você irá aprender como criar rostos, como
              preparar um bom esboço e fazer com que seu desenho fique simétrico
              e mais realista. Afinal esse é o início de muitos artistas.
            </S.GeneralTextCapitular>
          </S.GeneralBox>
          <S.GeneralBox>
            <S.Generalh3>Módulo 3</S.Generalh3>
            <S.GeneralTextTitle>Variação de Ângulos</S.GeneralTextTitle>
            <Img
              fluid={module03Image.childImageSharp.fluid}
              alt="Imagem com o módulo 3 do curso, mostrando o painel do curso."
            />
            <S.GeneralTextCapitular>
              Nesse módulo você irá aprender a desenhar posições diferentes.
              Isso é bem comum, e uma das coisas mais difíceis até mesmo para
              artistas mais experientes. Por isso nesse módulo vamos aprender a
              fazer um bom sketch e deixar os desenhos de forma correta com a
              proporção certa.
            </S.GeneralTextCapitular>
          </S.GeneralBox>
        </S.GeneralSection>
        <S.GeneralSection>
          <S.GeneralBox>
            <S.Generalh3>Módulo 4</S.Generalh3>
            <S.GeneralTextTitle>Anatomia do Corpo</S.GeneralTextTitle>
            <Img
              fluid={module04Image.childImageSharp.fluid}
              alt="Imagem com o módulo 4 do curso, mostrando o painel do curso."
            />
            <S.GeneralTextCapitular>
              Anatomia do corpo é um dos pontos mais fundamentais quando se
              trata de desenho, afinal ninguém quer um personagem desengonçado e
              muito menos fora da realidade e desconexa não é mesmo.
            </S.GeneralTextCapitular>
          </S.GeneralBox>
          <S.GeneralBox>
            <S.Generalh3>Módulo 5</S.Generalh3>
            <S.GeneralTextTitle>Cabelos</S.GeneralTextTitle>
            <Img
              fluid={module05Image.childImageSharp.fluid}
              alt="Imagem com o módulo 5 do curso, mostrando o painel do curso."
            />
            <S.GeneralTextCapitular>
              Recebemos muito pedidos de ajuda com o cabelo e por conta disso
              temos o módulo de cabelos para que você consiga fazer todos os
              tipos de cabelos.
            </S.GeneralTextCapitular>
          </S.GeneralBox>
          <S.GeneralBox>
            <S.Generalh3>Módulo 6</S.Generalh3>
            <S.GeneralTextTitle>Mãos e pés</S.GeneralTextTitle>
            <Img
              fluid={module06Image.childImageSharp.fluid}
              alt="Imagem com o módulo 6 do curso, mostrando o painel do curso."
            />
            <S.GeneralTextCapitular>
              Todo mundo, absolutamente todo mundo trava na hora de desenhar
              mãos e pés, por isso dedicamos um módulo exclusivamente para
              tratar esse ponto onde a maioria tem dificuldade.
            </S.GeneralTextCapitular>
          </S.GeneralBox>
        </S.GeneralSection>
        <S.GeneralSeparator witdh />
        <S.GeneralSubTitle>Como o método FanArt funciona?</S.GeneralSubTitle>
        <S.GeneralSection>
          <S.GeneralBox shadow>
            <S.GeneralIcon color="#70ca57">
              <Wifi2 />
            </S.GeneralIcon>
            <S.GeneralText>
              Curso 100% Online Assista a qualquer hora em qualquer lugar, seja
              no computador ou celular.
            </S.GeneralText>
          </S.GeneralBox>
          <S.GeneralBox shadow>
            <S.GeneralIcon color="#1fa1df">
              <PlayFill />
            </S.GeneralIcon>
            <S.GeneralText>
              +35 Aulas Com foco no seu aprendizado, práticas, objetivas e com
              exercícios.
            </S.GeneralText>
          </S.GeneralBox>
          <S.GeneralBox shadow>
            <S.GeneralIcon color="#faab4e">
              <Calendar />
            </S.GeneralIcon>
            <S.GeneralText>
              Acesso Vitalício. Pague uma vez, acesse para sempre e quantas
              vezes quiser.
            </S.GeneralText>
          </S.GeneralBox>
          <S.GeneralBox shadow>
            <S.GeneralIcon color="#de5aa5">
              <AccessTime />
            </S.GeneralIcon>
            <S.GeneralText>
              Liberado 24h/dia Acesse a hora que quiser, total liberdade, no seu
              tempo.
            </S.GeneralText>
          </S.GeneralBox>
        </S.GeneralSection>
        <S.GeneralSeparator />
        <S.GeneralCallToAction
          target="_blank"
          href="http://bit.ly/aprendendo-desenhar-fan-art"
        >
          Não perca tempo. Aproveite agora!
        </S.GeneralCallToAction>
        <S.GeneralSeparator />
        <S.GeneralSubTitle>
          O curso de desenho realmente é bom?
        </S.GeneralSubTitle>
        <S.GeneralText>
          Somos suspeitos a falar, afinal amamos o método fan art, então vou
          deixar aqui embaixo umas imagens que mostram depoimentos de alguns de
          nossos alunos.
        </S.GeneralText>
        <S.GeneralText>
          Lembrando que somos mais de 10 mil alunos, incrível né :D
        </S.GeneralText>
        <SRLWrapper options={options}>
          <S.GeneralGaleria>
            <S.GeneralImagePequena
              shadow
              fluid={depoimento1.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com um desenho do Deidara"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento2.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com desenho do Naruto"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento3.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com desenho do Naruto"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento4.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com rascunho de uma garota"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento5.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com alguns exemplos"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento6.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com v'rios olhos e rascunhos de personagens"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento7.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com um desenho do naruto e sasuke"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento8.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com vários desenhos do rosto de naruto"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento9.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com o desenho do kamus de aquario"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento10.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com desenho do naruto"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento11.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com um desenho do vegeta"
            />
            <S.GeneralImagePequena
              shadow
              fluid={depoimento12.childImageSharp.fluid}
              alt="Depoimento dos alunos sobre o curso com o desenho do luffy"
            />
          </S.GeneralGaleria>
        </SRLWrapper>
        <S.GeneralText>
          Além de tudo isso que vocês estão vendo e espero que assim como eu
          esteja adorando, temos para vocês também alguns bônus que vou deixar
          abaixo:
        </S.GeneralText>
        <S.GeneralSubTitle>Bônus do Método Fan Art</S.GeneralSubTitle>
        <S.GeneralSection>
          <S.GeneralBox>
            <S.Generalh3>Melhorando seu Traço</S.Generalh3>
            <Img
              fluid={bonus01Image.childImageSharp.fluid}
              alt="mostrando a área de membros com um curso de bônus"
            />
            <S.GeneralList>
              <S.GeneralListItem>- Como melhorar seu traço</S.GeneralListItem>
              <S.GeneralListItem>
                - Os maiores erros de quem está começando e como evitá-los
              </S.GeneralListItem>
              <S.GeneralListItem>
                - Como saber se você está evoluindo seu traço
              </S.GeneralListItem>
            </S.GeneralList>
          </S.GeneralBox>
          <S.GeneralBox>
            <S.Generalh3>Comunidade Exclusiva</S.Generalh3>
            <Img
              fluid={bonus03Image.childImageSharp.fluid}
              alt="imagem com a comunidade exclusiva do facebook"
            />
            <S.GeneralList>
              <S.GeneralListItem>
                - Tirar dúvidas direto com a professora e outros alunos
              </S.GeneralListItem>
              <S.GeneralListItem>
                - Participar de sorteios e ganhar descontos em outros cursos
              </S.GeneralListItem>
              <S.GeneralListItem>
                - Trocar aprendizado e interagir com outros alunos
              </S.GeneralListItem>
            </S.GeneralList>
          </S.GeneralBox>
        </S.GeneralSection>
        <S.GeneralSection>
          <S.GeneralBox>
            <S.Generalh3>Certificado de Conclusão</S.Generalh3>
            <Img
              fluid={bonus04Image.childImageSharp.fluid}
              alt="imagem do certificado de conclusão"
            />
            <S.GeneralList>
              <S.GeneralListItem>
                - No final do curso você irá ter um certificado para comprovar
                todo seu empenho e dedicação ao longo da sua jornada.
              </S.GeneralListItem>
            </S.GeneralList>
          </S.GeneralBox>
          <S.GeneralBox shadow></S.GeneralBox>
        </S.GeneralSection>
        <S.GeneralSeparator witdh />
        <S.GeneralText>
          Isso é tudo que tenho para te mostrar, meu papel aqui é que você possa
          sim aprender a desenhar, realizar o seu grande sonho de desenhar seus
          personagens favoritos.
        </S.GeneralText>
        <S.GeneralBlockQuote>
          Você é totalmente capaz de desenhar, sem ter a necessidade de nascer
          com o ‘dom’. O dom nada mais é de uma desculpa daqueles que desistiram
          antes de praticar, treinar e evoluir para alcançar seus objetivos.
          Acredite em você e nós te mostraremos o caminho.
        </S.GeneralBlockQuote>
        <S.GeneralText>
          Sem mais delongas vamos ao que interessa, quanto custa para você
          aprender tudo isso e finalmente realizar seus sonhos de desenhar seus
          personagens favoritos e criar seus próprios personagens.
        </S.GeneralText>
        <S.GeneralText>
          Vamos ver tudo que você estará adquirindo e o valor de cada item:
        </S.GeneralText>
        <S.GeneralBox>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Curso Completo Método Fan Art 3.0{" "}
            </S.GeneralStrongText>
            <S.GeneralTextThroughLine>de R$297,00</S.GeneralTextThroughLine>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Bônus Exclusivo: Como Melhorar o Seu Traço{" "}
            </S.GeneralStrongText>
            <S.GeneralTextThroughLine>de R$127,00</S.GeneralTextThroughLine>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Atualizações de Novas Aulas{" "}
            </S.GeneralStrongText>
            <S.GeneralTextThroughLine>de R$227,00</S.GeneralTextThroughLine>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Desconto Exclusivo Em Outros Cursos{" "}
            </S.GeneralStrongText>
            <S.GeneralTextThroughLine>Não tem preço</S.GeneralTextThroughLine>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Bônus Exclusivo: Acesso ao Grupo Exclusivo de Alunos{" "}
            </S.GeneralStrongText>
            <S.GeneralTextThroughLine>Não tem preço</S.GeneralTextThroughLine>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Bônus Exclusivo: Certificado de Conclusão{" "}
            </S.GeneralStrongText>
            <S.GeneralTextThroughLine>Não tem preço</S.GeneralTextThroughLine>
          </S.GeneralTextDiscount>
        </S.GeneralBox>
        <S.GeneralFinalBoxPrice>
          <S.GeneralText>
            <S.GeneralStrongText>Tudo isso deveria custar:</S.GeneralStrongText>
          </S.GeneralText>
          <S.GeneralTextThroughLine size="4rem">
            Total: R$ 651,00
          </S.GeneralTextThroughLine>
        </S.GeneralFinalBoxPrice>
        <S.GeneralText>
          <S.GeneralStrongText>
            Porém se você tomar sua decisão agora e quiser garantir um bom
            desconto eu consigo para você o valor de:
          </S.GeneralStrongText>
        </S.GeneralText>
        <S.GeneralSeparator />
        <S.GeneralStrongText>
          <S.GeneralCallToAction
            target="_blank"
            href="http://bit.ly/aprendendo-desenhar-fan-art"
          >
            R$97,00 á vista ou 10x de R$11,08
          </S.GeneralCallToAction>
        </S.GeneralStrongText>
        <S.GeneralSeparator />
        <S.GeneralImageSafe
          fluid={safeImage.childImageSharp.fluid}
          alt="Imagem com os selos de segurança do site, compra segura, satisfação garantida e privacidade protegida"
        />
        <S.GeneralText>
          É isso mesmo amigos, todo acesso ao método fan art 3.0 por esse valor
          e tem mais ainda, possuímos uma garantia de satisfação de 7 dias
        </S.GeneralText>
        <S.GeneralSection>
          <S.GeneralBox shadow>
            <S.GeneralImagePequena
              fluid={metodoGarantiaImage.childImageSharp.fluid}
              alt="Imagem de garantia, 7 dias de garantias incondicional"
            />
          </S.GeneralBox>
          <S.GeneralBox shadow>
            <S.GeneralStrongText>
              Fique 100% Satisfeito Ou Peça Seu Dinheiro De Volta.
            </S.GeneralStrongText>
            <S.GeneralText>
              O Método Fan Art 3.0 conta com uma garantia 100% de satisfação. Se
              durante 7 dias você acessar o conteúdo e achar que o curso não é
              para você, basta pedir reembolso e devolverei seu dinheiro de
              forma integral. Sem perguntas e questionamentos. Neste caso, os
              riscos serão todos meus.
            </S.GeneralText>
          </S.GeneralBox>
        </S.GeneralSection>
        <S.GeneralFinalCall
          target="_blank"
          href="http://bit.ly/aprendendo-desenhar-fan-art"
        >
          Sim, quero aprender a desenhar agora.
        </S.GeneralFinalCall>
        <S.GeneralSubTitle>Alguma Dúvida?</S.GeneralSubTitle>
        <S.GeneralText>
          Temos as perguntas mais frequentes abaixo e a resposta é sempre{" "}
          <S.GeneralStrongText>SIM</S.GeneralStrongText>
        </S.GeneralText>
        <S.GeneralBox>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              É seguro comprar o curso Método Fan Art 3.0?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Você realmente devolve o dinheiro caso eu não goste do curso?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Posso parcelar em até 10x no cartão de crédito?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Posso pagar em 2 cartões diferentes?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Terei acesso imediato à área exclusiva de aluno(a) do curso?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Ganharei acesso as aulas em vídeo + audio + texto?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Ao finalizar minha compra ganho acesso imediato ao Grupo de
              Alunos?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Ao terminar o curso terei acesso ao meu certificado?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              É verdade que já consigo ver meu resultado ou do meu filho(a) já
              nas primeiras semanas?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              Eu sinto que não consigo aprender ou que não tenho o dom para
              desenhar. Seu curso vai me ensinar mesmo assim a desenhar meus
              personagens favoritos?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              As técnicas ensinadas no curso servem para meu filho(a) que tem
              entre 8 e 16 anos?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
          <S.GeneralTextDiscount>
            <S.GeneralStrongText>
              As técnicas ensinadas servem para mim que já sou velho e sinto que
              passei da idade de aprender a desenhar?
            </S.GeneralStrongText>
            <S.GeneralStrongText>SIM</S.GeneralStrongText>
          </S.GeneralTextDiscount>
        </S.GeneralBox>
        <S.GeneralSubTitle>
          Siga os 3 passos para garantir sua vaga no Método Fan Art 3.0
        </S.GeneralSubTitle>
        <S.GeneralText>
          Seu acesso imediato ao curso Método Fan Art 3.0 está a{" "}
          <S.GeneralStrongText>3 simples passos de você.</S.GeneralStrongText>
        </S.GeneralText>
        <S.GeneralText>Tudo o que você precisa fazer é</S.GeneralText>
        <S.GeneralText>1. Clicar no botão abaixo:</S.GeneralText>
        <S.GeneralText>
          2. Preencher as informações de pagamento 100% seguro e garantido com
          encriptação de dados.
        </S.GeneralText>
        <S.GeneralText>
          3. Receber nosso email imediato com os dados de login e senha para seu
          acesso.
        </S.GeneralText>
        <S.GeneralStrongText>Siga o passo #1 abaixo:</S.GeneralStrongText>
        <S.GeneralFinalCall
          target="_blank"
          href="http://bit.ly/aprendendo-desenhar-fan-art"
        >
          Sim, quero aprender a desenhar agora.
        </S.GeneralFinalCall>
      </S.GeneralWrapper>
    </Layout>
  )
}

export default IndexPage
